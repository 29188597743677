.CallPlayer {
  /* padding: 0px !important; */
  font-size: 0.85em;
  height: auto;
  min-height: 120px;
  overflow: hidden;
  position: relative;
}

.timelines {
  /* position: relative; */
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
}

.customer,
.agent {
  width: 100%;
  margin-top: '10px';
  /* border: 1px solid red; */
}

.active_conversation {
  position: absolute;
  border-radius: 100px;
  cursor: pointer;
  height: 100%;
}

.customer_track,
.agent_track {
  background-color: #f5f5f5;
  width: 100%;
  position: relative;
  height: 13px;
  border-radius: 4px;
}

.customer_saying {
  background-color: #d2b9fa;
}

.agent_saying {
  background-color: #f090d2;
}

.marker {
  position: absolute;
  height: 135px;
  padding: 0px 2px;
  top: 0px;
  background-color: #00000040;
  z-index: 10;
  cursor: pointer;
}

.marker-container {
  position: relative;
  background: transparent;
  height: 100%;
  width: 4px;
  margin-left: -4px;
}

.arrow {
  position: absolute;
  top: 0px;
  right: -9px;
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-top: 10px solid #8e9499;
}

.bar {
  position: absolute;
  bottom: 0px;
  right: -9px;
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 10px solid transparent;
}
